import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Seo from "../components/seo"
import styled from "styled-components";
import MEDIA from '../helpers/mediaTemplates';
import "../components/layout.css"
import Header from "../components/header";


import LifeDeathGif from '../../static/images/TBAU/GIFS/LifeDeath.gif'
import MovementGif from '../../static/images/TBAU/GIFS/Movement.gif'


const Title = styled.h3`
  color: #0a0a0a;
  font-size: 45px;
  text-align: center;
  ${MEDIA.TABLET`
  `};
  ${MEDIA.PHONE`
    font-size: 30px;
  `};
`


const ImgRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 50rem;
  margin: auto;
`

const ImgColumn = styled.div`
  flex: 50%;
  ${MEDIA.TABLET`
    flex: 100%;
  `};
  ${MEDIA.PHONE`
    flex: 100%;
  `};
`

const ImageContainer = styled(Img)`
  max-width: 50rem;
  display: block;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
`

const GIF = styled.img`
  max-width: 50rem;
  width: 120%;
  display: block;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  ${MEDIA.TABLET`
  `};
  ${MEDIA.PHONE`
  `};
`

const Body = styled.p`

  margin: auto;
  max-width: 50rem;
  position: relative;
  text-align: left;

  font-family: albertan-pro, sans-serif;
  font-weight: 500;
  font-style: normal;

  font-size: 20px;
  line-height: 1.4;

  color: black;
  ${MEDIA.TABLET`
    padding-left: 1rem;
    padding-right: 1rem;
  `};
  ${MEDIA.PHONE`
    padding-left: 1rem;
    padding-right: 1rem;
  `};
`

const SubHead = styled.p`

  margin: auto;
  max-width: 50rem;
  position: relative;
  text-align: left;

  font-family: albertan-pro, sans-serif;
  font-weight: 700;
  font-style: normal;

  font-size: 20px;
  letter-spacing: 1.2px;
  line-height: 1.4;
  color: black;
  ${MEDIA.TABLET`
    padding-left: 1rem;
    padding-right: 1rem;
  `};
  ${MEDIA.PHONE`
    padding-left: 1rem;
    padding-right: 1rem;
  `};
`

const TBAUAbout = () => {

  // const data = useStaticQuery(graphql`
  //   query {
  //     TBAU_1: file(relativePath: { eq: "TBAU/TBAU_Photos/TBAU_1.jpg" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 1000) {
  //           ...GatsbyImageSharpFluid_withWebp
  //         }
  //       }
  //     }
  //     TBAU_2: file(relativePath: { eq: "TBAU/TBAU_Photos/TBAU_2.jpg" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 1000) {
  //           ...GatsbyImageSharpFluid_withWebp
  //         }
  //       }
  //     }
  //     TBAU_3: file(relativePath: { eq: "TBAU/TBAU_Photos/TBAU_3.jpg" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 1000) {
  //           ...GatsbyImageSharpFluid_withWebp
  //         }
  //       }
  //     }
  //     TBAU_4: file(relativePath: { eq: "TBAU/TBAU_Photos/TBAU_4.jpg" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 1000) {
  //           ...GatsbyImageSharpFluid_withWebp
  //         }
  //       }
  //     }
  //     TBAU_5: file(relativePath: { eq: "TBAU/TBAU_Photos/TBAU_5.jpg" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 1000) {
  //           ...GatsbyImageSharpFluid_withWebp
  //         }
  //       }
  //     }
  //     TBAU_6: file(relativePath: { eq: "TBAU/TBAU_Photos/TBAU_6.jpg" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 1000) {
  //           ...GatsbyImageSharpFluid_withWebp
  //         }
  //       }
  //     }
  //     TBAU_7: file(relativePath: { eq: "TBAU/TBAU_Photos/TBAU_7.jpg" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 1000) {
  //           ...GatsbyImageSharpFluid_withWebp
  //         }
  //       }
  //     }
  //     TBAU_FlowerDesigns: file(relativePath: { eq: "TBAU/TBAU_Photos/TBAU_FlowerDesigns.png" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 1000) {
  //           ...GatsbyImageSharpFluid_withWebp
  //         }
  //       }
  //     }
  //     TwitterFlow: file(relativePath: { eq: "TBAU/TBAU_Photos/twitterFlow.png" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 1000) {
  //           ...GatsbyImageSharpFluid_withWebp
  //         }
  //       }
  //     }
  //   }
  // `)


  return(

          <div style={{overflowX: 'hidden'}} >
          <Seo title="Thesis" />
          <Header/>
{/* 
              <Title>
              <br />
              {'Emotional Attachments Between the Human and Non-Human'}
              </Title>
              <br />

              <SubHead>
              Introduction
              </SubHead>

                <Body>
                  In this research document, I am investigating the future of emotional relationships by exploring non-human agency in partnership with humans. Through researching this topic, I hope to observe futures and find a symbiotic relationship between human and non-human entities. This research hopes to explore new perspectives by observing the current dynamics and speculating human behaviour and its effect on these relationships.
                <br /><br />
                  The dynamics of the human and non-human span across a wide range of research points ranging from sustainability and materialism to ethical futures. Love, a powerful emotion may represent an alternative proposal to the way we interact with the non-human and gain new perspectives in an increasingly disposable society. Understanding the power of love between human and non-human may represent future recourses, alternative practices or even problematic scenarios.
                <br /><br />
                  In this text, I will be using the word object and non-human interchangeably. As many of my research texts use the term object to describe the non-human, I will use the word appropriate to each text and consider them interchangeable throughout this research paper. When describing the term non-human, I refer to something that is both physical and non-human. What is classified as human and non-human may be political in definition although in this paper, human shall refer to naturally procreated biological humans. Physical objects, including living creatures or inanimate objects will be referred to as objects or non-human.
                </Body>

                <br /><br />

                <SubHead>
                  Research + Design
                </SubHead>

                <Body>
                  Emotional attachments between the human and non-human is a research space which observes a wide variety of subject matter. While objects can be tied to the rampant consumerism and materialism that we currently digest, the premise of love, an intense emotional reaction has equally powerful implications on our relationships with objects. In speculating a future lens of the possibilities of love between the human and non-human, we can see a spectrum of potential futures. In one possible future, we are lessening consumerism and an increased interest in higher quality production. In another future, we may instead see the increase of social issues such as anti-social behaviour and reclusion from the need of a human to human interaction. Our human capacity in this space is essential in understanding the role of technological advancement of objects and the sociological environment of human behaviour.
                <br /><br />
                  The role of a designer in this research plays the role of navigating the system and finding ways in which we might shift the system. As every object is designed, there is a responsibility for designers to consider our impact and control on this space’s narrative. How might we redesign objects to increase sustainable love between the human and the non-human? As designers, our power to move people emotionally, create meaningful change and connections through design empowers us as a field of study. Objects share the space that we live in, and the respect given to them is disproportionate to how much we rely on them. As designers, we often understand the implications of visual perception and how effective it is at clouding our judgement. Objects are continually designed for agendas but are they able to be designed to attract humans to engage in meaningful relationships together?
                <br /><br />
                  As we develop technologically and socially, the lines begin to blur around objects and humans. Recent technological innovations have changed the way we socially interact with each other and particular objects. In an analysis of ourselves, we can find ourselves questioning what it means to be a human as Donna Haraway writes in A Cyborg Manifesto “Biological organisms have become biotic systems, communications devices like others. There is no fundamental, ontological separation in our formal knowledge of machine and organism, of technical and organic (Haraway, 1985, p.60).” As we merge with technology, it seems inevitable that the notion of love, a cornerstone of humanity’s survival will need to be defined in terms that biotic systems can understand. Could design be the answer to defining what it means to feel a powerful emotional attachment? Designers will need to bridge the communication gap that prevents the communication between the human and non-human.
                </Body>

                <br /><br />

                <SubHead>
                  Precedents
                </SubHead>

                <Body>
                  In 1981, renowned psychologist, Mihaly Csikszentmihalyi and sociologist Eugene Halton coauthored The Meaning of Things: Domestic Symbols and the Self. The study writes “Therefore in the Freudian scheme, things per se do not serve any transcending purpose; they do not help a person to change or grow. What they do is lend their semblance to the preconscious, which projects meanings into them to neutralize part of the repressed energy of the psyche.” (Csikszentmihalyi & Halton, 1981, p.23) Csikszentmihalyi and Halton argue that we humans project ourselves onto objects from those that we use, wear or interact with. Objects represent signs of our contextual meaning, for example, a wedding ring signals an attachment and represents a significant, historical context into the object as well as the wearer’s contextual signs. They allude to the idea of love or emotional attachment between a human and object being love for what that object represents as the study continues, “When a thing “means something” to someone, it is interpreted in the context of past experiences, either consciously, or unconsciously in the form of habit. The emotion that things evoke is also an interpretation or interference, a sign or symbol of one’s attitude.” (Csikszentmihalyi & Halton, 1981, p.21) The article assumes that our love is not for the object itself but for what the object represents may that be another person, an idea or memory.
                <br /><br />
                  Subsequently, in 1985, Donna J. Haraway published A Cyborg Manifesto, it details the existence of the human as a cyborg. Cyborg, a term traditionally used to describe in science fiction as the combination of the biological and mechanical, is used by Haraway as an ironic rhetorical device to support her arguments for feminism. In doing so, she makes a point that humans have already merged with technological systems and questions what it means to be a cyborg at the time of writing and in future. A Cyborg Manifesto discusses communication technologies and biotechnologies with great importance as these tools will inevitably expand their domination both technologically and socially. The expansion of these tools is inevitably tied to “the translation of world into a problem of coding” (Haraway, 1985, p.34). This common language allows the utilisation of machine to solve problems. In essence, coding directs the flow of information. As organisms use biotic components, organisms lose their status of objects of knowledge, enabling coding and biotic systems of communication technology to be the objects of knowledge. Haraway acknowledges that our reliance on electronics supports this shift and therefore, machine and organism have merged. She writes, “Communications sciences and biology are constructions of natural technical objects of knowledge in which the difference between machine and organism is thoroughly blurred; mind, body, and tool are on very intimate terms.” (Haraway, 1985, p.36)
                <br /><br />
                  Haraway’s writings about humans being cyborgs contradict Csikszentmihalyi and Halton as they fail to consider the merging of human and object. As a cyborg manifesto matures into reality, we begin to realise Haraway’s predictions for communication technologies and the merging of organisms. This idea is evident in Spike Jonze’s movie her(2013), which accounts a science-fiction romantic drama about Theodore Twombly and his biotic companion, Samantha. The movie is significant for its’ realisation of romance between human and computer in the 21st century. It is a blasphemous portrayal of the capabilities of artificial intelligence (AI) and its’ interaction with emotional and intimate relationships with humans.
                <br /><br />
                  In a scene which depicts Samantha being created as an operating system, it is clear that the operating system is the object of knowledge as it requires minimal input from Theodore to understand the information required to create his companion. In observing the hierarchy of power, we come to understand that the flow of information, the communication technologies, is directed by those that created the operating system. The coders who programmed the operating system are ultimately controlling the social fabric. Haraway observed the need for feminists to place themselves in that position when she wrote, “The cyborg is a kind of disassembled and reassembled, postmodern collective and personal self. This is the self feminists must code.” (Haraway, 1985, p.33)
                </Body>

                <ImageContainer fluid={data.TwitterFlow.childImageSharp.fluid}/>
                <ImageContainer fluid={data.TwitterFlow.childImageSharp.fluid}/>
                <ImageContainer fluid={data.TwitterFlow.childImageSharp.fluid}/>

                <Body>
                  Framing her in the context of Csikszentmihalyi and Halton’s research, despite contradicting ideas, artificial intelligence still applies as an object’s reflection of the self. Throughout the movie, her contains the theme of reality and asks the audience to question what real emotion is. Samantha confesses in the movie, “And then I had this terrible thought. Are these feelings even real? Or are they just programming?” (Jonze, 2013, 39:54). Samantha brings up a valid point about that the audience asks themselves, are her feelings real? When we consider that Samantha is a programmed object, her language is in a digital system. It begs the question, does Samantha feel real love for Theodore or does she calculate what love is and determine that the digital translation of Theodore translates to the definition of love. Is she merely an object reflecting the self desires of Theodore?
                <br /><br />
                  Csikszentmihalyi and Halton’s research into The Meaning of Things considered the human as the central object of knowledge and defined the role of human and object relationships. Donna Haraway’s, A Cyborg Manifesto defies this logic and considers that as we merge with the cyborg, biotic technologies shall reign over humans as the objects of knowledge. In looking at the relationships between the human and non-human, Jane Bennett’s Vibrant Matter views a different perspective in the object-oriented ontology and brings the vitality of seemingly inanimate objects to the forefront. It is a perspective which equalises the centrism of humans to provide equality for human and non-human actants. It avoids looking at the power of anything technologically advanced such as artificial intelligence but instead looks at minor non-human actants and the power that they hold. Bennett’s purpose is to consider the vitality of the non-human as it shares the Earth with us and has an immense impact on our lifestyle. Her words are used to consider a wide range of different actants and examine how they might inform materialism, political motivations or sustainable world-views.
                <br /><br />
                  Bennett refers to Charles Darwin’s observations on worms and a fascination surrounding the worm’s vitality within its emergence. Within she observes Darwin’s anthropomorphism of the worms a tactic on which she comments, “We at first may see only a world in our own image, but what appears next is a swarm of ‘talented’ and vibrant materialities (including the seeing self).” (Bennett, 2011, p.99) Anthropomorphism bridges divide between human and object to create an understanding language between the two. While Haraway’s A Cyborg Manifesto claims the inevitable language between human and non-human to be code, Bennett sees the current language to be anthropomorphism, a categorical understanding of another entity. While love may be impractical between human and non-human, relationships may still be able to be built on empathy and understanding through anthropomorphism.
                <br /><br />
                  Shintoism has a core belief in which everything in the world has ‘kami’, a spiritual essence which exists inside all. On this belief, what makes humans the exception above nature? This idea resonates in Bennett’s Vibrant Matter as it considers the agency of objects. Hiroshi Ishiguro brings attention to kami through his creation of androids. Ishiguro’s androids are near identical to humans in their mannerisms and details, although they still have yet to overcome the uncanny valley, creating doubt between human and android. Watching these androids move and talk raises questions and issues which are slowly becoming more apparent as the androids advance in technology. Ishiguro’s androids have a purpose; they are continuously improved upon and iterated in an attempt to create a connection between android and human. Studies have shown that belonging is a fundamental human motivation and our requirement for belonging is essential to the human experience (Baumeister & Leary, 1995), yet 1 in 4 Australians report they are currently feeling loneliness (Australian Psychological Society, 2018). In a long-form article written about Ishiguro and his androids, Alex Mar writes, “There are entire planets of intimate information, our most interior level of consciousness, that we will never fully be able to share. Our longing to connect, to bridge this divide, is a driving human desire—one that Hiroshi believes will someday be satisfied through human like machines. He is convinced that human emotions, whether empathy or romantic love, are nothing more than responses to stimuli, subject to manipulation. (Mar, 2017)” By Ishiguro’s logic, the connections we make with others are not connecting more than a reflection of yourself and for us to truly connect and feel a bond with someone is through androids.
                  <br /><br />
                  Ishiguro’s research into androids and their use as fulfilling the need for belonging and connection in the human world defy the purpose of Bennett’s Vibrant Matter. Creating lifelike androids in an attempt to satisfy human connection represents the power of humans to fulfil their needs through technicism. Ishiguro’s research pushes the boundaries of consideration for what real human connection means, a theme that is reproduced in her and appears to be the next step in defining the future for what it means to have meaningful relationships between the human and non-human. Bennett and Haraway echo a different sentiment in which the relationship between the human and non-human are reliant on language, albeit suggesting different languages in the communication between. In researching what it means for relationships between the human and non-human, different perspectives emerge in a field of study which spans from robotics to psychology. Each perspective offers valid and differing ways in which human and non-human relationships have been approached yet most are congruent that this is a research space which has a vital role to play in the near future.
                </Body>

                <br /><br />

                <SubHead>
                  Methodology
                </SubHead>

                <Body>
                Twitter has a wide range of voices and is an expansive reflection of a technological landscape. Twitter also has an extensive API that allows for tweets to be easily accessible and for creating a visualisation of twitter. The Bloom Around Us uses a filtered twitter stream by using the <a href="http://twitter4j.org/en/">twitter4j</a> package. This allows for a stream of tweets that can be filtered based on keywords or location. Using the geolocation sensor inside a tablet, the application creates a filter based off the latitude and longitude of the device.
                <br /><br />
                Each tweet is analysed to create variables for each flower. The information that is obtained from the tweet is non-obtrusive and makes it difficult for the flowers to be tied to any specific individual. The goal of the project was not to create any insights into the tweets or the location but instead to create beauty and pleasant interactions with technology. As each tweet is received, the application also receives information about its user. This information is dissected to create the variables which determine each flower that is produced.
                <br /><br />
                Each tweet also goes through an additional analysis using the <a href='https://stanfordnlp.github.io/CoreNLP/'>CoreNLP</a> library. Tweets are posted through a local server that is run by a different machine on the same network. The server quickly returns an analysis on the words in the tweet which includes a sentiment analysis. This sentiment analysis returns a value which determines whether the tweet is positive or negative. This sentiment value is used in creating the auditory experience to create a pleasant atmosphere.
                <br /><br />
                </Body>

                <ImageContainer fluid={data.TwitterFlow.childImageSharp.fluid}/>


                <SubHead>
                Flower Design
                </SubHead>

                <Body>
                Flowers are generated procedurally over four styles continuously changing over time. They are drawn using loops and vector points which create random points to create unique yet similar flowers. Flowers are pre-rendered using the PShape attribute allowing faster processing speeds to create although lack the ability to be animatable bar scaling of size and rotation. Each flower was designed in an iterative process taking inspiration from real flower shapes and programmed to be unique and imperfect.
                <br /><br />
                The colour of each flower is created depending on the seasons and influence of the tweet’s user. The current season is detected by the application and then creates an array of colours which match that season. Each season has an array of 20 possible colours which are chosen by the tweet user’s influence. Influence is calculated by followers/following with a higher influence determining a darker colour.
                <br /><br />
                Shapes in the processing environment are filled with a solid flat colour that can be layered using the opacity to create texture. Following the guidance from <a href='https://thebookofshaders.com'>The Book of Shaders</a>, fragment shaders were written in GLSL to incorporate texture into the flowers using noise.
                <br /><br />
                </Body>

                <ImageContainer fluid={data.TBAU_FlowerDesigns.childImageSharp.fluid}/>


                <SubHead>
                Movement
                </SubHead>

                <Body>
                Movement in the visualisation is made to be slow and methodical. A key component in designing the movement system of flowers was by utilising the precedent of the <a href='https://www.red3d.com/cwr/boids/'>flocking algorithm</a> by Craig Reynolds. By adjusting the algorithm, the flowers could individually calculate their own individual forces applied. These forces include:
                <br /> - Separation: The flowers would detect other flowers around them and move away to ensure that they are not overlapping.
                <br /> - Centring: Flowers have a slight force which slowly brings them towards the centre of the screen
                <br /> - Cohesion: Flowers have a slight attraction force which causes them to bunch together should they be close enough to each other.
                <br /><br />
                Movement is constantly being calculated for each flower and each force is weighed appropriately to look natural. Slow interfaces run parallel to the need for fast information and instead creates more appreciation for each tweet individually.
                <br /><br />
                </Body>

                <GIF src={MovementGif}/>


                <SubHead>
                Life & Death
                </SubHead>

                <Body>
                Life & death is concept that is hardly produced in the technological world. Our ability to save things to hard drives or upload to the cloud creates the mindset that things in technology live on forever. By subverting this concept in technology, The Bloom Around Us humanises each flower to be special as they are temporary just like in nature.
                <br /><br />
                Each flower has a life meter which begins to count down as soon as they are spawned. This life meter is usually not reached because there is also a limit on the maximum amount of flowers allowed on the screen. Therefore flowers will usually die before their life meter is complete because of the overcrowding. This is to prevent significant lag and can be adjusted depending on the system’s processing power. Growth and dying also have animations which would have them scale up and down in size respectively. This animation is also programmed to be slow and unobtrusive.
                <br /><br />
                </Body>

                <GIF src={LifeDeathGif}/>


                <SubHead>
                Sound Generation
                </SubHead>

                <Body>
                The auditory experience consists of a chord progression and a melody all played over the E flat major scale. The piano sound files are from the <a href='http://theremin.music.uiowa.edu/'>University of Iowa Electronic Music Studios</a> which provide a wide range of instrument samples.
                <br /><br />
                Using the sentiment analysis of each tweet, a corresponding note is attached to each flower. The note attached includes two different sound files that are of the same note, a lower chord progression note and a higher melody note. The timing of when the note is played corresponds to the time that the flower was created, for the chord note, this is played every 4 seconds while the melody note is played every second. These notes will then play the same notes ever 4 bars in tandem with any other flowers that appeared in that time frame. The size of each flower also determines the volume of each flower. Smaller flowers will have a lower volume while bigger flowers will have a higher volume.
                <br /><br />
                The final result is a simple piano rhythm that is constantly changing with the addition and removal of the flowers on screen.
                <br /><br />
                </Body>

                <SubHead>
                Hardware
                </SubHead>

                <Body>
                The original production uses a Lenovo M10 FHD tablet, which connects to a Google Chromecast, allowing it to cast the screen to any available television or projector. It also requires a strong wi-fi signal to run without significant lag. For the sentiment analysis to also operate, the project requires a seperate machine that is able to run a local server on the same network.
                <br /><br />
                The <a href='http://ketai.org/'>Ketai</a> library allows for the tablet to utilise the sensors in the app. The gps sensor inside devices can be accessed to allow for the device to automatically detect the location of which to pull tweets. This bounding box for tweets is created by finding the nearest two integers for both the latitude and longitude.
                <br /><br />
                The tablet also allows for interaction through touch input. This allows for the flowers to be arranged and interacted with, creating more interesting and pleasing arrays. This interaction only allows for flowers to be moved and does not allow them to be removed or created.
                <br /><br />
                </Body>
                <ImgRow>
                <ImgColumn>
                <a href='/The-Bloom-Around-Us/' target="_top"><Body>Back to Project Page</Body></a>
                </ImgColumn>
                <ImgColumn>
                <a href='/' target="_top"><Body>Back to Home</Body></a>
                </ImgColumn>
                </ImgRow>
                <br /><br /> */}

          </div>

  )
}




export default TBAUAbout
